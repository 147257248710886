import React from "react";
import { styled } from "../../../stitches.config";
import { Cards } from "../../types";
import { TextColorType } from "../../types/pageBuilder";
import { DynamicPagesRichTextParser } from "../PageBuilder/shared/RichTextParser";

type Props = {
  cards: Cards[];
  backgroundColor: string;
};

export const SolutionCards: React.FC<Props> = ({ cards, backgroundColor }) => {
  return (
    <CardContainer>
      {cards.map((card, i) => {
        const background =
          card.cardBackgroundColor === "page_color"
            ? backgroundColor
            : "#F4F5F7";
        const P = p(card.cardTextContentColor);
        const Li = li(card.cardTextContentColor);
        const BuilderWrapper = builderWrap(card.cardTextContentColor);
        return (
          <Card key={i} css={{ background }}>
            {!!card.title && (
              <Title textColor={card.cardTextContentColor}>{card.title}</Title>
            )}
            <Text>
              <DynamicPagesRichTextParser
                customStyles={{ BuilderWrapper, Container, P, Li }}
                content={card.cardContent}
              />
            </Text>
          </Card>
        );
      })}
    </CardContainer>
  );
};

const CardContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "0 16px",
  margin: "0 auto",
  maxWidth: 657,
  gap: 30,
  "@md": {
    maxWidth: "unset",
    padding: "0",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
});

const Card = styled("div", {
  padding: 20,
  maxWidth: 625,
  borderRadius: 8,
  "@md": {
    width: 625,
    padding: 40,
  },
});

const Item = styled("li", {
  color: "$heading",
  fontWeight: 400,
  fontSize: 17,
  lineHeight: "24px",
  margin: "12px 0",
  "&:first-of-type": {
    margin: "32px 0 12px",
  },
  "&:last-of-type": {
    margin: "12px 0 0",
  },
});

const Title = styled("h3", {
  margin: "0 0 18px",
  fontWeight: 700,
  fontSize: 24,
  lineHeight: "28px",
  "@md": {
    margin: "0 0 32px",
  },
  variants: {
    textColor: {
      light: { color: "$white" },
      dark: {
        color: "$heading",
      },
    },
  },
});

const Text = styled("div", {
  color: "$heading",
  fontWeight: 400,
  fontSize: 17,
  lineHeight: "24px",
  textAlign: "start",
  margin: "0 0 18px",
  "@md": {
    margin: "0 0 32px",
  },
  "&:last-of-type": {
    margin: "0",
  },
});

const p = (textColor: TextColorType) => {
  return styled("p", {
    color: textColor === "light" ? "$white" : "$heading",
    fontWeight: 400,
    fontSize: 17,
    lineHeight: "24px",
    textAlign: "start",
    margin: "0 0 18px",
  });
};

const li = (textColor: TextColorType) =>
  styled("li", {
    color: textColor === "light" ? "$white" : "$heading",
    fontWeight: 400,
    fontSize: 17,
    lineHeight: "24px",
    textAlign: "start",
  });

const Container = styled("div", {
  lineHeight: "24px",
  textAlign: "start",
});

const builderWrap = (textColor: TextColorType) => {
  return styled("div", {
    color: textColor === "light" ? "$white" : "$heading",
    fontWeight: 400,
    fontSize: "17",
    lineHeight: "24px",
    textAlign: "start",
    margin: "0 0 18px",
    "@md": {
      margin: "0 0 16px",
    },
    "&:last-of-type": {
      margin: "0",
    },
  });
};
