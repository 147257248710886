import { GetServerDataProps, graphql, PageProps } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import React, { useEffect } from "react";
import { sendRequestToAPI } from "../../api/graphqlRequest";
import { Solution } from "../../types";
import { SolutionView } from "../../views/SolutionView/SolutionView";

interface ContextParams {
  [key: string]: string;
}

type SolutionsPageData = {
  notFound: boolean;
  solution: Solution;
};

const SolutionPage: React.FC<PageProps> = ({ serverData }) => {
  const { navigate } = useI18next();
  const { notFound, solution } = serverData as SolutionsPageData;

  useEffect(() => {
    notFound && navigate("/404", { replace: true });
  }, [notFound]);

  if (notFound) {
    return null;
  }
  return <SolutionView solution={solution} />;
};

export async function getServerData(context: GetServerDataProps) {
  const { id } = context.params as ContextParams;

  const getData = async () => {
    try {
      const { data } = await sendRequestToAPI(
        `
        query($slug: String, $locale: I18NLocaleCode){
          solutions(locale:$locale, filters:{slug:{eq: $slug }}){
            data{
              id
              attributes{
                 name
                 slug
                card {
                  title
                  cardContent
                  cardTextContentColor
                  cardBackgroundColor
                }
                document {
                  documentName
                  document {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
                shortDescription
                pageColorScheme
                heroTitle
                heroInfo 
                statisticsSlider {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              logo{data{attributes{url}}}
            }}
          }
        }
      `,
        { slug: id, locale: context.pageContext.language },
        {},
        process.env.GATSBY_API_URL
      );
      return data;
    } catch (err) {
      return err;
    }
  };
  const { data } = await getData();
  const notFound = !data?.solutions?.data?.length;
  const pageData: SolutionsPageData = {
    solution: data.solutions.data[0],
    notFound,
  };

  return {
    props: { ...pageData },
  };
}

export default SolutionPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
